

.create {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .button {
            margin-left: 40%;
            align-self: flex-start;
        }
    }
    h2 {
        width: 60%;
    }
}

iframe {
    margin-top: 30px;
    width: 60%;
}

.videoCredit {
    font-size: 10px;
    font-style: italic;
    width: 60%;
}

.createText {
    width: 60%;
    margin-left: 40%;

}

.readMore {
    transition: all 0.3s;
}

.createText .hiddenText {
    opacity: 0;
    display: none;
    transition: all 0.3s;
}