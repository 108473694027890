article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: \201C \201D \2018 \2019; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.visuallyhidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0);
  overflow: hidden; }

@font-face {
  font-family: 'Work Sans';
  src: url("../fonts/worksans-italic-webfont.woff2") format("woff2"), url("../fonts/worksans-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Work Sans';
  src: url("../fonts/worksans-medium-webfont.woff2") format("woff2"), url("../fonts/worksans-medium-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Work Sans';
  src: url("../fonts/worksans-regular-webfont.woff2") format("woff2"), url("../fonts/worksans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Work Sans';
  src: url("../fonts/worksans-bold-webfont.woff2") format("woff2"), url("../fonts/worksans-bold-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

body,
html {
  transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1); }

body {
  position: relative;
  font-family: "Work Sans";
  font-size: 17px; }

.no-js {
  visibility: hidden;
  opacity: 0; }

.js {
  visibility: visible;
  opacity: 1; }

.wrapper {
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  position: relative; }

.ui-loader {
  display: none; }

.noClick {
  pointer-events: none;
  cursor: pointer; }

section {
  padding: 100px 0;
  overflow: hidden; }

ul {
  padding: 0;
  list-style: none; }

h2 {
  font-size: 10vw;
  line-height: 8vw;
  width: 50%;
  margin: 0; }

a {
  text-decoration: none;
  transition: all 0.5s;
  color: black; }
  a:focus {
    outline: none; }

.button {
  font-size: 20px;
  font-weight: 900;
  padding: 5px;
  display: inline-block; }
  .button:before {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-color: black;
    left: 0;
    transition: all 0.5s;
    position: relative;
    margin-bottom: 3px; }
  .button:after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-color: black;
    transition: all 0.5s;
    position: relative;
    left: 0;
    margin-top: 3px; }
  .button:hover:before {
    width: calc(100% + 20px);
    left: -10px;
    background-color: #E63232; }
  .button:hover:after {
    width: calc(100% + 20px);
    left: -10px;
    background-color: #E63232; }

.readMore {
  font-size: 20px;
  color: #E63232; }
  .readMore:hover {
    color: black; }

.gallery {
  width: 90%;
  position: relative; }
  .gallery .pinkPic {
    width: 50%;
    border: 20px solid #C76161;
    position: relative;
    z-index: 2;
    left: 48vw;
    opacity: 0;
    transition: all 0.3s; }
  .gallery .bluePic {
    width: 45%;
    position: relative;
    border: 20px solid #9FD9EF;
    z-index: 1;
    right: 17vw;
    top: 10vw;
    opacity: 0;
    transition: all 0.3s; }
  .gallery .yellowPic {
    width: 50%;
    position: relative;
    border: 20px solid #F7E982;
    z-index: 3;
    left: 44vw;
    bottom: 3vw;
    opacity: 0;
    transition: all 0.3s; }
  .gallery .photoCredit {
    position: relative;
    font-size: 10px;
    font-style: italic;
    width: 50%;
    left: 44vw;
    bottom: 3.5vw;
    opacity: 0;
    transition: all 0.3s; }

.gallery.secondGallery {
  transform: translateX(-24vw); }

.--red {
  color: #E63232; }

.--borderPink {
  color: #C76161; }

.--borderBlue {
  color: #9FD9EF; }

.--borderYellow {
  color: #F7E982; }

.--medium {
  font-weight: 700; }

.--bold {
  font-weight: 900; }

.--italic {
  font-style: italic; }

.subhead {
  font-size: 20px;
  font-weight: 900; }

.--uppercase {
  text-transform: uppercase; }

.headerMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  position: absolute;
  z-index: 98;
  width: 100%; }
  .headerMenu h3 {
    font-size: 25px;
    margin: 0; }

.menuButton {
  display: flex;
  align-items: center; }
  .menuButton a {
    font-size: 25px; }

.menuButtonActive:before {
  width: calc(100% + 20px);
  left: -10px;
  background-color: #E63232; }

.menuButtonActive:after {
  width: calc(100% + 20px);
  left: -10px;
  background-color: #E63232; }

.overlay {
  display: none;
  transition: all 0.5s;
  opacity: 0;
  background-color: white;
  position: fixed;
  height: 100vh;
  z-index: 97;
  top: 0;
  bottom: -10vh;
  left: 0;
  right: 0; }
  .overlay .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    margin-top: 150px;
    height: calc(100% - 200px); }
  .overlay p {
    font-size: 20px; }

.overlayActive {
  opacity: 1; }

.menuLink {
  font-size: 40px; }
  .menuLink:hover {
    color: red; }

h1 {
  font-size: 13vw;
  margin: 0;
  line-height: 10vw;
  text-align: right;
  position: relative;
  z-index: 2; }

.headerContent {
  padding-top: 150px; }

.firstGallery {
  top: -30px;
  z-index: 1; }

.aboutContent {
  margin-left: 50%; }

.create .wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .create .wrapper .button {
    margin-left: 40%;
    align-self: flex-start; }

.create h2 {
  width: 60%; }

iframe {
  margin-top: 30px;
  width: 60%; }

.videoCredit {
  font-size: 10px;
  font-style: italic;
  width: 60%; }

.createText {
  width: 60%;
  margin-left: 40%; }

.readMore {
  transition: all 0.3s; }

.createText .hiddenText {
  opacity: 0;
  display: none;
  transition: all 0.3s; }

.client .wrapper h2 {
  width: 80%; }

.clientList {
  display: flex;
  justify-content: space-between; }
  .clientList .--red {
    font-size: 20px; }
  .clientList a:hover {
    color: #E63232; }

.clientColumn {
  margin-right: 10px; }

.reviewBlock div {
  margin-top: 50px; }

.reviewBlock a {
  margin-top: 25px; }

.contact {
  padding-bottom: 150px; }
  .contact h2 {
    width: 65%;
    margin-left: 35%;
    margin-bottom: 60px; }

.contactList {
  width: 60%;
  margin-left: 35%; }

.contactItem {
  margin-bottom: 30px; }
  .contactItem p {
    margin: 0; }
  .contactItem a:hover {
    color: red; }

.formBlock {
  width: 65%;
  margin-left: 35%; }

form {
  display: flex;
  flex-direction: column; }
  form input,
  form textarea {
    background-color: #f7f5f5;
    margin-bottom: 10px;
    border: none;
    padding: 10px 20px; }
    form input::placeholder,
    form textarea::placeholder {
      color: #bdbdbd; }
  form textarea {
    height: 200px; }
  form button {
    width: 169px;
    background-color: white;
    border: none; }

.thankYou {
  font-size: 36px;
  text-align: center;
  opacity: 0;
  display: none;
  transition: all 0.3s; }

.goingUp {
  position: absolute;
  bottom: -161px;
  right: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center; }
  .goingUp:hover .arrow {
    transform: translateY(40px); }
  .goingUp p.button:after {
    display: none; }
  .goingUp p.button:before {
    width: 30%;
    margin: 0 auto 3px auto; }
  .goingUp p.button:hover:before {
    background-color: black;
    left: 0; }
  .goingUp .arrow {
    display: inline-block;
    font-size: 68px;
    line-height: 0;
    transform: translateY(56px);
    transition: all 0.3s; }

footer {
  margin-top: 100px;
  background-color: black;
  color: white;
  font-size: 20px;
  height: 100px; }
  footer .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  footer a {
    color: white; }
    footer a:hover {
      color: #E63232; }
  footer p:nth-of-type(2) {
    font-size: 16px; }
  footer br {
    display: none; }

@media (min-width: 1620px) {
  h1 {
    font-size: 180px;
    line-height: 148px; }
  .gallery .pinkPic {
    width: 583px;
    left: 743px; }
  .gallery .bluePic {
    width: 580px;
    top: 146px;
    right: 256px; }
  .gallery .yellowPic {
    width: 543px;
    left: 684px;
    bottom: 50px; }
  .gallery .photoCredit {
    left: 684px;
    bottom: 57px; }
  h2 {
    font-size: 151px;
    line-height: 129px; } }

@media (max-width: 964px) {
  .clientList {
    flex-direction: column; }
  footer {
    height: auto;
    padding: 40px 0; }
    footer .wrapper {
      flex-direction: column;
      align-items: flex-start; }
    footer br {
      display: block; }
  .hideMobile {
    display: none; } }

@media (max-width: 724px) {
  a.menuLink {
    font-size: 6vw; }
    a.menuLink:hover {
      color: black;
      font-size: 45px; }
  body {
    font-size: 19px; }
  h1 {
    font-size: 15vw;
    line-height: 12vw; }
  .wrapper {
    width: 90%; }
  .firstGallery {
    margin-top: 18px; }
  .gallery {
    width: 100%; }
    .gallery .pinkPic {
      left: 11vw;
      width: 90%; }
    .gallery .bluePic {
      right: 2vw;
      top: -5vw;
      width: 98%; }
    .gallery .yellowPic {
      bottom: 11vw;
      left: 5vw;
      width: 91%; }
    .gallery .photoCredit {
      bottom: 11vw;
      left: 5vw;
      width: 91%; }
  h2 {
    width: 100%;
    font-size: 13.1vw;
    line-height: 12vw; }
  .button {
    font-size: 25px;
    margin-top: 30px; }
  .menuButton .button {
    margin-top: 0; }
  section.about {
    padding-top: 0; }
  .aboutContent {
    margin: 30px 0 0 0; }
  section.galleryTwo {
    padding-bottom: 0; }
  .gallery.secondGallery {
    transform: translateX(0); }
  section.create {
    padding-top: 0; }
    section.create .wrapper .button {
      margin-left: 0; }
    section.create h2, section.create iframe {
      width: 100%; }
    section.create .videoCredit {
      width: 100%; }
  .createText {
    margin-left: 0;
    width: 100%; }
  .contact {
    padding-bottom: 0px; }
    .contact h2 {
      margin-left: 0; }
    .contact .contactList,
    .contact .formBlock {
      margin-left: 0;
      width: 100%; }
  form button {
    width: 200px; }
  .goingUp {
    display: flex;
    position: relative;
    bottom: auto;
    right: auto;
    width: 199px;
    margin: 0 auto; }
    .goingUp .arrow {
      font-size: 54px; }
    .goingUp p {
      font-size: 25px; } }
