// MIXINS

@mixin position($type, $top, $right, $bottom, $left) {
    position: $type; 
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin display($type, $JC, $AI) {
    display: $type; 
    justify-content: $JC;
    align-items: $AI;
}

// colors

$red: #E63232;
$pink: #C76161;
$blue: #9FD9EF;
$yellow: #F7E982;


// fotns

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/worksans-italic-webfont.woff2') format('woff2'),
         url('../fonts/worksans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/worksans-medium-webfont.woff2') format('woff2'),
         url('../fonts/worksans-medium-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/worksans-regular-webfont.woff2') format('woff2'),
         url('../fonts/worksans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/worksans-bold-webfont.woff2') format('woff2'),
         url('../fonts/worksans-bold-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}
