.headerMenu {
    h3 {
        font-size: 25px;
        margin: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    position: absolute;
    z-index: 98;
    width: 100%;
}

.menuButton {
    display: flex;
    align-items: center;

    a {
        font-size: 25px;
    }
}

.menuButtonActive {
    &:before {
        width: calc(100% + 20px);
        left: -10px;
        background-color: $red;
    }
    &:after {
        width: calc(100% + 20px);
        left: -10px;
        background-color: $red;
    }
}

.overlay {
    display: none;
    transition: all 0.5s;
    opacity: 0;
    background-color: white;
    position: fixed;
    height: 100vh;
    z-index: 97;
    top: 0;
    bottom: -10vh;
    left: 0;
    right: 0;
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
        margin-top: 150px;
        height: calc(100% - 200px);
    }
    p {
        font-size: 20px;
    }
}

.overlayActive {
    opacity: 1;
}

.menuLink {
    font-size: 40px;
    &:hover {
        color: red;
    }
}