body,
html {
    transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
}

body {
    position: relative;
    font-family: "Work Sans";
    font-size: 17px;
}

.no-js {
 visibility: hidden;
 opacity: 0;
}

.js {
 visibility: visible;
 opacity: 1;
}

.wrapper {
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;

}

.ui-loader {
    display: none;
}

.noClick {
    pointer-events: none;
    cursor: pointer;
}

section {
    padding: 100px 0;
    overflow: hidden;
}

ul {
    padding: 0;
    list-style: none;
}


h2 {
    font-size: 10vw;
    line-height: 8vw;
    width: 50%;
    margin: 0;
}
        

a {
    text-decoration: none;
    transition: all 0.5s;
    color: black;
    &:focus {
        outline: none;
    }
}

.button {
    font-size: 20px;
    font-weight: 900;
    padding: 5px;
    display: inline-block;
    &:before {
        content: '';
        display: block; 
        width: 100%;
        height: 4px;
        background-color: black;
        left: 0;
        transition: all 0.5s;
        position: relative;
        margin-bottom: 3px;
    }
    &:after {
        content: '';
        display: block; 
        width: 100%;
        height: 4px;
        background-color: black;
        transition: all 0.5s;
        position: relative;
        left: 0;
        margin-top: 3px;
    }
    &:hover:before {
        width: calc(100% + 20px);
        left: -10px;
        background-color: $red;
    }
    &:hover:after {
        width: calc(100% + 20px);
        left: -10px;
        background-color: $red;
    }
}

.readMore {
    font-size: 20px;
    color: $red;
    &:hover {
        color: black;
    }
}

.gallery {
    width: 90%;
    position: relative;
    .pinkPic {
        width: 50%;
        border: 20px solid $pink;
        position: relative;
        z-index: 2;
        left: 48vw;
        opacity: 0;
        transition: all 0.3s;
    }
    .bluePic {
        width: 45%;
        position: relative;
        border: 20px solid $blue;
        z-index: 1;
        right: 17vw;
        top: 10vw;
        opacity: 0;
        transition: all 0.3s;
    }
    .yellowPic {
        width: 50%;
        position: relative;
        border: 20px solid $yellow;
        z-index: 3;
        left: 44vw;
        bottom: 3vw;
        opacity: 0;
        transition: all 0.3s;
    }
    .photoCredit {
        position: relative;
        font-size: 10px;
        font-style: italic;
        width: 50%;
        left: 44vw;
        bottom: 3.5vw;
        opacity: 0;
        transition: all 0.3s;
    }
}



.gallery.secondGallery {
    transform: translateX(-24vw);
}

// colors

.--red {
    color: $red;
}

.--borderPink {
    color: $pink;
}

.--borderBlue {
    color: $blue;
}

.--borderYellow {
    color: $yellow;
}


//fonts

.--medium {
    font-weight: 700;;
}

.--bold {
    font-weight: 900;
}

.--italic {
    font-style: italic;
}

.subhead {
    font-size: 20px;
    font-weight: 900;
}

.--uppercase {
    text-transform: uppercase;
}