
h1 {
    font-size: 13vw;
    margin: 0;
    line-height: 10vw;
    text-align: right;
    position: relative;
    z-index: 2;
}

.headerContent {
    padding-top: 150px;
}

.firstGallery {
    top: -30px;
    z-index: 1;
}