//  MEDIA QUERIES

 // wide screens
@media (min-width: 1620px) {

    //  typography
    h1 {
        font-size: 180px;
        line-height: 148px;
    }

    .gallery {
        .pinkPic {
            width: 583px;
            left: 743px;
        }
        .bluePic {
            width: 580px;
            top: 146px;
            right: 256px;
        }
        .yellowPic {
            width: 543px;
            left: 684px;
            bottom: 50px;
        }
        .photoCredit {
            left: 684px;
            bottom: 57px;
        }
    }

    h2 {
        font-size: 151px;
        line-height: 129px;
    }
}

//tablet
@media (max-width: 964px) {

    // client
    .clientList {
        flex-direction: column;
    }

    //footer
    footer {
        height: auto;
        padding: 40px 0;
        .wrapper {
            flex-direction: column;
            align-items: flex-start;
        }
        br {
            display: block;
        }
    }

    .hideMobile {
        display: none;
    }
}

// mobile
@media (max-width: 724px) {

    // menu 
    a.menuLink {
        font-size: 6vw;
        &:hover {
            color: black;
            font-size: 45px;
        }
    }

    //typography
    body {
        font-size: 19px;
    }

    h1 {
        font-size: 15vw;
        line-height: 12vw;
    }

    .wrapper {
        width: 90%;
    }

    .firstGallery {
        margin-top: 18px;
    }

    .gallery {
        width: 100%;
        .pinkPic {
            left: 11vw;
            width: 90%;
        }
        .bluePic {
            right: 2vw;
            top: -5vw;
            width: 98%;
        }
        .yellowPic {
            bottom: 11vw;
            left: 5vw;
            width: 91%;
        }
        .photoCredit {
            bottom: 11vw;
            left: 5vw;
            width: 91%;
        }

    }

    h2 {
        width: 100%;
        font-size: 13.1vw;
        line-height: 12vw;
    }

    .button {
        font-size: 25px;
        margin-top: 30px;
    }

    .menuButton .button {
        margin-top: 0;
    }

    // about 
    section.about {
        padding-top: 0;
    }

    .aboutContent {
        margin: 30px 0 0 0;
    }

    // second gallery
    section.galleryTwo {
        padding-bottom: 0
    }

    .gallery.secondGallery {
        transform: translateX(0);
    }

    //create
    section.create {
        padding-top: 0;
        .wrapper {
            .button {
                margin-left: 0;
            }
        }
        h2, iframe {
            width: 100%;
        }
        .videoCredit {
            width: 100%;
        }
    }

    .createText {
        margin-left: 0;
        width: 100%;
    }

    //contact

    .contact {
        padding-bottom: 0px;
        h2 {
            margin-left: 0;
        }
        .contactList,
        .formBlock {
            margin-left: 0;
            width: 100%;
        }
    }

    form {
        button {
            width: 200px;
        }
    }

    .goingUp {
        .arrow {
            font-size: 54px;
        }
        display: flex;
        position: relative;
        bottom: auto;
        right: auto;
        width: 199px;
        margin: 0 auto;
        p {
            font-size: 25px;
        }
    }



}