
.contact {
    h2 {
        width: 65%;
        margin-left: 35%;
        margin-bottom: 60px;
    }
    padding-bottom: 150px;
}

.contactList {
    width: 60%;
    margin-left: 35%;
}

.contactItem {
    margin-bottom: 30px;
    p {
        margin: 0;
    }
    a {
        &:hover {
            color: red;
        }
    }
}

.formBlock {
    width: 65%;
    margin-left: 35%;
}

form {
    display: flex;
    flex-direction: column;
    input,
    textarea {
        background-color: #f7f5f5;
        margin-bottom: 10px;
        border: none;
        padding: 10px 20px;
        &::placeholder {
            color: #bdbdbd; 
        }
    }
    textarea {
        height: 200px;
    }
    button {
        width: 169px;
        background-color: white;
        border: none;
    }
}

.thankYou {
    font-size: 36px;
    text-align: center;
    opacity: 0;
    display: none;
    transition: all 0.3s;
}

.goingUp {
    position: absolute;
    bottom: -161px;
    right: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    &:hover .arrow {
        transform: translateY(40px);

    }
    p.button {
        &:after {
            display: none;
        }
        &:before {
            width: 30%;
            margin: 0 auto 3px auto;
        }
        &:hover:before {
            background-color: black;
            left: 0;
        }
       
    }
    .arrow {
        display: inline-block;
        font-size: 68px;
        line-height: 0;
        transform: translateY(56px);
        transition: all 0.3s;
    }
}

// footer

footer {
    margin-top: 100px;
    background-color: black;
    color: white;
    font-size: 20px;
    height: 100px;
    .wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    a {
        color: white;
        &:hover {
            color: $red;
        }
    }
    p {
        &:nth-of-type(2) {
            font-size: 16px;
        }
    }
    br {
        display: none;
    }
}