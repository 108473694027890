
.client {
    .wrapper {
        h2 {
            width: 80%;
        }
    }
}

.clientList {
    display: flex;
    justify-content: space-between;
    .--red {
        font-size: 20px;
    }
    a {
        &:hover {
            color: $red;
        }
    }
}

.clientColumn {
    margin-right: 10px;
}

.reviewBlock {
    div {
        margin-top: 50px;
    }
    a {
        margin-top: 25px;
    }
}